import { OpenMicrophonesModal } from '../../../services/event-bus/events'

export const MicrophoneSettings = () => {
    return (
        <div className="flex justify-center mb-8">
            <div className="text-primary font-medium hover:cursor-pointer" onClick={() => OpenMicrophonesModal.emit({})}>
                Microphone Settings
            </div>
        </div>
    )
}
