import { PencilIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { updateTitleReducer } from '../../../redux/reducers/visits-slice'
import { visitsService } from '../../../services/http/visits.service'
import { Loader } from '../../UI/Loader'
import { Tooltip } from '../../UI/Tooltip'

interface Props {
    initialTitle: string
}

export const DictationTitle = ({ initialTitle }: Props) => {
    const [isEditing, setIsEditing] = useState(false)
    const [isHover, setIsHover] = useState(false)
    const [prevTitle, setPrevTitle] = useState(initialTitle)
    const [title, setTitle] = useState(initialTitle)
    const [isLoading, setIsLoading] = useState(false)

    const { id } = useParams()
    const dispatch = useDispatch()

    const handleSave = async () => {
        setIsEditing(false)
        setIsHover(false)

        if (prevTitle === title.trim()) return

        if (title.length > 0 && id) {
            try {
                setIsLoading(true)
                await visitsService.updateVisit(id, { title })
                setPrevTitle(title)
                dispatch(updateTitleReducer({ _id: id, title }))
                toast.success('Title changed successfully')
            } catch (error) {
                setTitle(prevTitle)
                toast.error('Title update failed')
            } finally {
                setIsLoading(false)
            }
        }
    }

    const onClickHandler = () => {
        setIsEditing(true)
        setIsHover(false)
    }

    const handleHover = {
        setHover() {
            setIsHover(true)
        },
        unsetHover() {
            setIsHover(false)
        }
    }
    return (
        <div className="relative">
            {isEditing ? (
                <div className="flex justify-between items-center gap-1">
                    <input
                        id="title"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        onBlur={handleSave}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') handleSave()
                        }}
                        autoFocus
                        className="w-fit border text-lg font-semibold text-gray-700 border-gray-100 border-x-0 border-t-0 focus:outline-none"
                    />
                </div>
            ) : (
                <>
                    <Tooltip content={<span>Click to edit</span>} placement="top">
                        <h1
                            onMouseEnter={handleHover.setHover}
                            onMouseLeave={handleHover.unsetHover}
                            onClick={onClickHandler}
                            className="transition-all border-b border-transparent flex gap-2 items-center text-lg font-semibold text-gray-700 cursor-text"
                        >
                            {title}
                            <PencilIcon width={15} fill="#374151" className={`${isHover ? 'block' : 'hidden'} transition-all`} />
                            {isLoading && <Loader className="text-primary" />}
                        </h1>
                    </Tooltip>
                </>
            )}
        </div>
    )
}
