import classNames from 'classnames'

const RecordingIcon = ({ className, fill, ...props }: React.ComponentProps<'svg'>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className={classNames('size-4', className)} {...props}>
            <rect x="3" y="4" width="3" height="12" rx="1.5" fill={fill} />
            <rect x="9" y="2" width="3" height="16" rx="1.5" fill={fill} />
            <rect x="15" y="5" width="3" height="10" rx="1.5" fill={fill} />
        </svg>
    )
}

export default RecordingIcon
