import classNames from 'classnames'

const PDFIcon = ({ className, ...props }: React.ComponentProps<'svg'>) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" className={classNames('size-4', className)} {...props}>
            <path
                d="M11.3787 3.5L4.5 3.5V10H3V3.5C3 2.67157 3.67157 2 4.5 2H11.3787C11.7765 2 12.158 2.15804 12.4393 2.43934L16.5607 6.56066C16.842 6.84197 17 7.2235 17 7.62132V10H15.5V7.62132L11.3787 3.5ZM4.24805 11H2.49805H1.99805H1.88266C1.67025 11 1.49805 11.1722 1.49805 11.3846V11.5V15.1154V17.5C1.49805 17.7761 1.7219 18 1.99805 18H2.49805C2.77419 18 2.99805 17.7761 2.99805 17.5V15.5H4.24805C5.49069 15.5 6.49805 14.4926 6.49805 13.25C6.49805 12.0074 5.49069 11 4.24805 11ZM4.24805 14H2.99805V12.5H4.24805C4.66226 12.5 4.99805 12.8358 4.99805 13.25C4.99805 13.6642 4.66226 14 4.24805 14ZM8.99805 16.5V12.5H9.99805C10.5503 12.5 10.998 12.9477 10.998 13.5V15.5C10.998 16.0523 10.5503 16.5 9.99805 16.5H8.99805ZM7.49805 11.5C7.49805 11.2239 7.7219 11 7.99805 11H9.99805C11.3788 11 12.498 12.1193 12.498 13.5V15.5C12.498 16.8807 11.3788 18 9.99805 18H7.99805C7.7219 18 7.49805 17.7761 7.49805 17.5V11.5ZM14.0006 11.4489C14.0262 11.1967 14.2392 11 14.498 11H14.998H18.0029C18.2791 11 18.5029 11.2239 18.5029 11.5V12C18.5029 12.2761 18.2791 12.5 18.0029 12.5H15.498V14H17.498C17.7742 14 17.998 14.2239 17.998 14.5V15C17.998 15.2761 17.7742 15.5 17.498 15.5H15.498V17.5C15.498 17.7761 15.2742 18 14.998 18H14.498C14.2219 18 13.998 17.7761 13.998 17.5V15V14.5V12V11.5C13.998 11.4827 13.9989 11.4657 14.0006 11.4489Z"
                fill="#6B7280"
            />
        </svg>
    )
}

export default PDFIcon
