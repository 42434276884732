import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import MicrophoneIcon from './Icons/MicrophoneIcon'
import UsersIcon from './Icons/UsersIcon'

const DictationStyle = styled.span`
    .active {
        --tw-bg-opacity: 1;
        background-color: #ccfbf1;
    }
`
const VisitStyle = styled.span`
    .active {
        --tw-bg-opacity: 1;
        background-color: #e0f2fe;
    }
`

export const VisitDictationNavbar = () => {
    return (
        <div className="flex mb-3 sm:mt-3 mt-14 justify-between gap-1 p-1 sm:p-2 border border-gray-300 rounded-full ">
            <VisitStyle>
                <NavLink to="/visits" className=" px-3 sm:px-5 md:px-12 py-2 text-slate-700 font-semibold flex items-center rounded-full hover:bg-[#eef8ff] transition-all">
                    <span className="mr-1 sm:mr-2">
                        <UsersIcon />
                    </span>
                    Patient Visit
                </NavLink>
            </VisitStyle>
            <DictationStyle>
                <NavLink to="/dictations" className={'flex items-center px-3 sm:px-5 md:px-12 py-2 text-slate-700 font-semibold active:bg- rounded-full hover:bg-teal-50'}>
                    <span className="mr-1 sm:mr-2">
                        <MicrophoneIcon />
                    </span>
                    Dictation
                </NavLink>
            </DictationStyle>
        </div>
    )
}
