import 'react-quill/dist/quill.snow.css'

import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { useMemo, useRef } from 'react'
import ReactQuill from 'react-quill'
import { useLocation, useParams } from 'react-router-dom'

import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { Visit } from '../../../services/models/Visit.model'
import { Button } from '../../UI/Button'
import { Loader } from '../../UI/Loader'
import { DeleteButton } from '../../Visit/components/Record/components/DeleteButton'
import { VisitInfo, VisitInfoMenu } from '../../Visit/components/VisitInfo'
import { LocalContextProvider } from '../../VisitDictationShared/hooks/useLocalContext'
import { useVisitsContext } from '../../Visits/hooks/useVisitsContext'
import { ControllerBar } from './ControllerBar'
import { DictationTitle } from './DictationTitle'
import { QuillEditor } from './QuillEditor/QuillEditor'

export const Record = () => {
    const { id } = useParams()
    const dictationFromState: Visit | undefined = useLocation().state?.dictation
    const { getVisit, areVisitsLoading: isLoading } = useVisitsContext().visits
    const dictation = useMemo(() => (id ? getVisit(id) : dictationFromState), [id, getVisit, dictationFromState])
    const { isMobile } = useMediaQuery()

    const editorRef = useRef<ReactQuill | null>(null)
    const parentRef = useRef<HTMLDivElement | null>(null)

    if (isLoading) {
        return (
            <div className="bg-white h-full flex justify-center items-center">
                <LoadingState />
            </div>
        )
    }

    if (!dictation || !id) return null

    return (
        <div className="bg-white h-full flex flex-col">
            {isMobile ? (
                <div className="pt-2 px-2 flex items-center pb-2 border-b">
                    <Button intent="text" label="" leftIconNode={<ChevronLeftIcon className="h-5 w-5 text-gray-400" />} onClick={() => {}} />
                    <DictationTitle initialTitle={dictation.title} />
                    <div className="flex">
                        <VisitInfoMenu className="mr-1" visit={dictation} />
                    </div>
                </div>
            ) : (
                <div className="pt-4 pb-3 px-3 sm:pb-4 sm:px-6 border-b flex w-full justify-between">
                    <div>
                        <div className="flex items-start justify-space">
                            <DictationTitle initialTitle={dictation.title} />
                        </div>
                        <VisitInfo className="mt-3 sm:mt-1" visit={dictation} />
                    </div>
                    <div>
                        <DeleteButton visitId={id} visitType="dictation" key={id} concise={true} />
                    </div>
                </div>
            )}

            <div className="flex flex-col flex-1 px-7 lg:px-14 xl:px-32 relative">
                <LocalContextProvider key={dictation._id} visitId={id} visitType="dictation" duration={dictation.duration}>
                    <div ref={parentRef} className="flex-1 ">
                        <QuillEditor quillRef={editorRef} ref={null} />
                    </div>

                    <ControllerBar />
                </LocalContextProvider>
            </div>
        </div>
    )
}

const LoadingState = () => {
    return (
        <div className="flex items-center justify-center text-gray-500 my-4">
            <Loader className="mr-3" />
            <span>Loading Dictation...</span>
        </div>
    )
}
