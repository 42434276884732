import classNames from 'classnames'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
    className?: string
    intent?: Intent
    iconNode: ReactNode
    onClick?(event?: any): void
}

type Intent = 'default' | 'danger'

const Styles = styled.button`
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const IconButton = ({ className, intent, iconNode, onClick }: Props) => {
    return (
        <Styles
            type="button"
            className={classNames(
                'rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-200',
                className,
                !!onClick && 'cursor-pointer',
                (intent === 'default' || !intent) && 'bg-gray-100',
                intent === 'danger' && 'bg-red'
            )}
            onClick={(event) => onClick && onClick(event)}
        >
            {iconNode}
        </Styles>
    )
}
