import { createContext, useContext } from 'react'

import { useAuthContext } from '../../Authentication/hooks/useAuthContext'
import { useView, ViewHookState } from './_useView'
import { useVisits, VisitsHookState } from './_useVisits'

interface ContextState {
    view: ViewHookState
    visits: VisitsHookState
}

const LocalStateContext = createContext<ContextState>(null!)

// Provider
export function VisitsContextProvider({ children }: any) {
    const { user } = useAuthContext().user
    const userId = user?._id

    const viewHookState = useView()
    const visitsHookState = useVisits(userId)

    return (
        <LocalStateContext.Provider
            value={{
                view: viewHookState,
                visits: visitsHookState
            }}
        >
            {children}
        </LocalStateContext.Provider>
    )
}

// Consumer
export function useVisitsContext() {
    return useContext(LocalStateContext)
}
