import classNames from 'classnames'
import styled from 'styled-components'

import { storage } from '../../lib/storage'

interface Props {
    className?: string
    label?: string
    cognitoAuthUrl ?: string
}

const Styles = styled.button`
    padding: 8px 12px;
    margin-top: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    background: white;

    &:hover {
        cursor: pointer;
        background: #fafafa;
    }

    img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }
`


export const GoogleAuthButton = ({ className, label, cognitoAuthUrl }: Props) => {
    if (!cognitoAuthUrl) {
        return null
    }

    return (
        <Styles
            type="button"
            className={classNames([
                className,
                'shadow-sm text-gray-500 text-sm',
            ])}
            onClick={() => (window.location.href = cognitoAuthUrl)}
        >
            <img src="/assets/images/google.svg" alt="Google" />
            <span>{label ?? 'Continue with Google'}</span>
        </Styles>
    )
}

// Check for session token in cookies
export const resolveSession = () => {
    const getCookie = (name: string): string | undefined => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) {
            return parts?.pop()?.split(';')?.shift() || undefined
        }
        return undefined
    }

    try {
        const cookie = getCookie('fl_session')
        if (!cookie) {
            return
        }
        const session = JSON.parse(cookie)
        storage.storeAccessToken(session)
        storage.clearEmrToken()
    } catch (error) {
    //
    }
}
