import { ChatBubbleLeftEllipsisIcon, MicrophoneIcon, TagIcon, UsersIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { GroupedVirtuoso } from 'react-virtuoso'
import styled from 'styled-components'

import Colors from '../../../colors'
import { MOMENT_DATETIME_FORMAT, MOMENT_TIME_FORMAT } from '../../../constants'
import { useAppContext } from '../../../hooks/useAppContext'
import { useRoute } from '../../../hooks/useRoute'
import { eventBus } from '../../../services/event-bus/eventBus.service'
import { VisitCreated } from '../../../services/event-bus/events'
import { CustomTemplate } from '../../../services/models/CustomTemplate.model'
import { Template } from '../../../services/models/Template.model'
import { Visit } from '../../../services/models/Visit.model'
import { Button } from '../../UI/Button'
import { DateLabel } from '../../UI/DateLabel'
import { DeleteVisitsByGroup } from '../../UI/DeleteVisitsByGroup'
import { DurationLabel } from '../../UI/DurationLabel'
import { Loader } from '../../UI/Loader'
import { SearchVisits } from '../../UI/SearchVisits'
import { VisitStatusIndicator } from '../../UI/VisitStatusIndicator'
import { useVisitsContext } from '../../Visits/hooks/useVisitsContext'
import { VisitMenu } from '../../Visits/VisitMenu'

const Styles = styled.div`
    height: 100%;
    overflow: hidden;
    border-right: 1px solid ${Colors.BORDER_COLOR};

    .delete-button {
        padding: 0 !important;
        box-shadow: none !important;
    }
`

export const Sidebar = () => {
    const { navVisit, navVisitRecord, navVisitStart, navDictationRecord } = useRoute()
    const { visitsGroups, visits, areVisitsLoading, areVisitsLoaded, getVisits } = useVisitsContext().visits
    const { showPreview } = useVisitsContext().view
    const virtualListRef = useRef<any>(null)

    const isEmpty = areVisitsLoaded && !visitsGroups.length

    const onViewVisit = useCallback(
        (event: any, visit: Visit) => {
            event.stopPropagation()
            showPreview()

            // Navigate page according to visit type
            switch (visit.type) {
                case 'dictation':
                    navDictationRecord(visit._id, visit)
                    break

                default:
                    switch (visit.state) {
                        case 'new':
                        case 'transcribing': {
                            navVisitRecord(visit._id, visit)
                            break
                        }

                        case 'processing':
                        case 'completed': {
                            navVisit(visit._id, visit)
                            break
                        }
                    }
            }

            // Navigate page according to visit state
        },
        [showPreview, navVisit, navVisitRecord, navDictationRecord]
    )

    useEffect(() => {
        const subscription = eventBus.getObservable().subscribe((event) => {
            switch (event.action) {
                // Scroll list to top on visit creation
                case VisitCreated.action: {
                    try {
                        virtualListRef.current.scrollToIndex({
                            index: 0,
                            behavior: 'smooth'
                        })
                    } catch (error) {
                        console.error(error)
                    }
                    break
                }
            }
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [visits])

    return (
        <Styles className="bg-white flex flex-col">
            <div className="flex p-2 sm:p-3">
                <SearchVisits className="flex-1 mr-3" />

                <Button
                    intent="text"
                    label="New Visit"
                    leftIconNode={<MicrophoneIcon className="h-5 w-5 text-gray-400" />}
                    onClick={() => {
                        showPreview()
                        navVisitStart()
                    }}
                />
            </div>

            {areVisitsLoading && <LoadingState />}

            {isEmpty && <EmptyState />}

            {visitsGroups.length > 0 && (
                <GroupedVirtuoso
                    ref={virtualListRef}
                    groupCounts={visitsGroups.map((i) => i.visitsCount)}
                    groupContent={(index: number) => {
                        const title = visitsGroups[index].type
                        const visitsIds = visitsGroups[index].visitsIds
                        return <StickyRow title={title} visitsIds={visitsIds} />
                    }}
                    itemContent={(index: number) => {
                        const visit = visits[index]
                        return <VisitRow visit={visit} onClick={(event: any) => onViewVisit(event, visit)} />
                    }}
                    endReached={() => getVisits()}
                />
            )}
        </Styles>
    )
}

const StickyRow = ({ title, visitsIds }: { title: string; visitsIds: string[] }) => {
    return (
        <div className={classNames('border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 font-medium leading-6 text-gray-900 flex items-center justify-between')}>
            <h3 className="truncate">{title}</h3>
            <DeleteVisitsByGroup
                visitsGroupTitle={title}
                visitsIds={visitsIds}
                childNode={(isLoading) => <Button intent="text-danger" className="delete-button border-0 ring-0 p-0" label="Delete Visits" isLoading={isLoading} />}
            />
        </div>
    )
}

const VisitRowStyles = styled.div`
    transition: background 0.25s ease;
`

const VisitRow = ({ visit, onClick }: { visit: Visit; onClick: (event: any) => void }) => {
    const { id } = useParams()
    const isSelected = useMemo(() => id === visit._id, [id, visit._id])

    const { getTemplate } = useAppContext().templates

    const template = useMemo((): Template | CustomTemplate | undefined => {
        if (visit.customTemplateId) {
            return getTemplate(visit.customTemplateId)
        } else if (visit.templateId) {
            return getTemplate(visit.templateId)
        }
        return undefined
    }, [visit, getTemplate])

    return (
        <VisitRowStyles
            className={classNames(
                'text-gray-900 px-3 py-3 border-b border-gray-200 cursor-pointer flex gap-3',
                // 'animate-fade-down animate-duration-[250ms] animate-ease-out', // Animation
                isSelected ? 'bg-primary-50' : 'hover:bg-gray-50'
            )}
            onClick={onClick}
        >
            <div className="flex mt-1">
                {visit.type === 'dictation' ? (
                    <span className="bg-[#2DD4BF] h-8 w-8 flex justify-center items-center rounded-3xl">
                        <MicrophoneIcon width={20} color="white" />
                    </span>
                ) : (
                    <span className="bg-[#38BDF8] h-8 w-8 flex justify-center items-center rounded-3xl">
                        <UsersIcon width={20} color="white" />
                    </span>
                )}
            </div>
            <div className="flex-1">
                <div className="flex items-center justify-between">
                    <VisitStatusIndicator className="mr-2" status={visit.state} />
                    <div className={classNames('flex-1 truncate font-medium', isSelected ? 'text-gray-900' : 'text-gray-600')}>{visit.title}</div>
                    <VisitMenu className="mb-1" visitId={visit._id} visitState={visit.state} />
                </div>
                <div className="flex justify-between mt-1 truncate text-sm text-gray-500">
                    <div>
                        <DateLabel date={visit.createdAt} format={MOMENT_DATETIME_FORMAT} />
                        {visit.duration > 0 && (
                            <>
                                <span>&nbsp;-&nbsp;</span>
                                <DateLabel date={visit.endedAt} format={MOMENT_TIME_FORMAT} />
                            </>
                        )}
                    </div>

                    {visit.duration > 0 && <DurationLabel className="ml-2" seconds={visit.duration} />}
                </div>
                {template && (
                    <div className="flex items-center mt-1 truncate text-sm text-gray-500">
                        <TagIcon className="h-5 w-5 text-gray-400 mr-1" />
                        <span>{template.name}</span>
                    </div>
                )}
            </div>
        </VisitRowStyles>
    )
}

const LoadingState = () => {
    return (
        <div className="flex items-center justify-center text-gray-500 my-4">
            <Loader className="mr-3" />
            <span>Loading...</span>
        </div>
    )
}

const EmptyState = () => {
    return (
        <div className="border border-dashed border-gray-300 rounded text-gray-400 mx-3 p-3">
            <div className="flex items-center justify-center">
                <ChatBubbleLeftEllipsisIcon className="h-6 w-6 text-gray-300 mr-2" />
                <span>No visits exist</span>
            </div>
        </div>
    )
}
