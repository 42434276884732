import { ChevronLeftIcon } from '@heroicons/react/20/solid'

import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { useVisitsContext } from '../../Visits/hooks/useVisitsContext'
import { Button } from '../Button'

export const ShowSidebarButton = () => {
    const { isMobile } = useMediaQuery()
    const { showListView } = useVisitsContext().view

    if (!isMobile) return null

    return (
        <div className="absolute inset-y-0 left-2 top-2">
            <Button intent="text" label="Visits" leftIconNode={<ChevronLeftIcon className="h-5 w-5 text-gray-400" />} onClick={showListView} />
        </div>
    )
}
