import {
    StartMedicalStreamTranscriptionCommand,
    TranscribeStreamingClient,
} from '@aws-sdk/client-transcribe-streaming'
import MicrophoneStream from 'microphone-stream'
import { toast } from 'react-toastify'

import { TranscribedContent } from '../services/event-bus/events'
import { transcriptionsService } from '../services/http/transcriptions.service'
import { Transcript, TranscriptPartial } from '../services/models/Visit.model'
import { audio, encodePCMChunk } from './audio'
import { screenLock } from './screenLock'
import { timer } from './timer'

export interface Credentials {
    accessKeyId: string
    secretAccessKey: string
    sessionToken: string
}

const REGION = process.env.REACT_APP_TRANSCRIBER_REGION!
const DEFAULT_SAMPLE_RATE = 48000
const MAX_CHUNK_SIZE = 48000

const isDev =
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'dev.fluent.health'

export class AwsTranscriber {
    visitId: string | null = null
    deviceId?: string
    client: TranscribeStreamingClient | null = null
    credentials: Credentials | null = null

    constructor(visitId: string, deviceId?: string) {
        this.visitId = visitId
        this.deviceId = deviceId
    }

    async start(microphoneStream: MicrophoneStream) {
        this.client = await getClient(this.visitId!, this.credentials)
        if (!this.client) {
            toast.error('Unable to connect to transcription service')
            return
        }

        screenLock.lock()

        if (isDev) {
            toast.success('Connected to AWS Transcriber service')
        }

        transcribe(this.client, this.visitId!, microphoneStream)
    }

    stop() {
        this.client?.destroy()
        this.client = null

        audio.stopStream()
        screenLock.unlock()
    }

    setCredentials(credentials: Credentials) {
        this.credentials = credentials
    }
}

const getClient = async (
    visitId: string,
    credentials: Credentials | null
): Promise<TranscribeStreamingClient | null> => {
    // Get transcriber credentials from server
    if (!credentials) {
        try {
            credentials = await transcriptionsService.getCredentials(visitId)
        } catch (error) {
            console.error(error)
            return null
        }
    }

    // Get user's system time offset
    let systemTimeOffsetInMs = undefined
    try {
        systemTimeOffsetInMs = await getTimeOffsetInMs()
    } catch (error) {
        console.error(error)
    }

    // Create transcriber client with credentials
    try {
        return new TranscribeStreamingClient({
            region: REGION,
            credentials,
            systemClockOffset: systemTimeOffsetInMs || undefined,
        })
    } catch (error) {
        console.error(error)

        // Try again with new credentials
        try {
            credentials = await transcriptionsService.getCredentials(visitId)
            return new TranscribeStreamingClient({
                region: REGION,
                credentials,
                systemClockOffset: systemTimeOffsetInMs || undefined,
            })
        } catch (error) {
            console.error(error)

            // Try again with different region
            try {
                credentials = await transcriptionsService.getCredentials(
                    visitId
                )
                const region = 'us-east-1'
                return new TranscribeStreamingClient({
                    region,
                    credentials,
                    systemClockOffset: systemTimeOffsetInMs || undefined,
                })
            } catch (error) {
                console.error(error)
                return null
            }
        }
    }
}

const transcribe = async (
    client: TranscribeStreamingClient,
    visitId: string,
    stream: any
) => {
    sendCommand(client, stream)
        .then(async (data) => {
            if (!data) {
                return
            }

            try {
                for await (const event of data.TranscriptResultStream || []) {
                    if (!event) {
                        return
                    }

                    for (const result of event.TranscriptEvent?.Transcript
                        ?.Results || []) {
                        let content = result?.Alternatives?.length
                            ? result?.Alternatives[0]?.Transcript
                            : undefined
                        if (!content) {
                            return
                        }

                        const timestamp = new Date()
                        const isPartial = result.IsPartial ? true : undefined
                        content = isPartial ? `${content} ...` : content

                        const transcript: Transcript | TranscriptPartial = {
                            isPartial,
                            content,
                            timestamp,
                        }

                        // Emit transcript content for component consumption
                        TranscribedContent.emit({
                            visitId,
                            transcript,
                        })

                        // Post completed transcript to visit
                        if (!isPartial) {
                            addTranscriptToVisit(visitId, transcript)
                        }
                    }
                }
            } catch (error) {
                console.error(error)
                toast.error('Unable to connect to transcription service')
            }
        })
        .catch((error) => {
            console.error(error)
        })
}

const sendCommand = (client: TranscribeStreamingClient, stream: any) => {
    const audioStream = async function* (stream: any) {
        try {
            for await (const chunk of stream) {
                if (chunk?.length <= MAX_CHUNK_SIZE) {
                    yield {
                        AudioEvent: {
                            AudioChunk: encodePCMChunk(chunk),
                        },
                    }
                }
            }
        } catch (error: any) {
            console.error(`Error in audio stream: ${error.message}`)
        }
    }

    const command = new StartMedicalStreamTranscriptionCommand({
        LanguageCode: 'en-US',
        MediaEncoding: 'pcm',
        MediaSampleRateHertz: DEFAULT_SAMPLE_RATE,
        Specialty: 'PRIMARYCARE',
        Type: 'CONVERSATION',
        AudioStream: audioStream(stream),
    })

    return client.send(command)
}

const addTranscriptToVisit = async (
    visitId: string,
    transcript: Transcript
): Promise<void> => {
    if (!transcript.content || !transcript.timestamp || !visitId) {
        return Promise.resolve()
    }
    const duration = timer.getSeconds()
    return transcriptionsService
        .createTranscript(visitId, transcript, duration)
        .catch((error) => {
            console.error('Error saving transcript to database:', error)
        })
}

// If user's offset from server time is greater than 5 minutes, then return the offset in milliseconds
const getTimeOffsetInMs = async (): Promise<number | undefined> => {
    try {
        const response = await fetch('https://worldtimeapi.org/api/ip')
        const data = await response.json()
        const serverTime: any = new Date(data.datetime)
        const clientTime: any = new Date()
        const timeOffset = serverTime - clientTime

        // Greater than a 5 minute offset
        if (Math.abs(timeOffset) > 5 * 60000) {
            console.error(
                'System time is off by ' +
                    Math.abs(timeOffset) +
                    ' milliseconds'
            )
            return timeOffset
        }
        return undefined
    } catch (error) {
        console.error(error)
        return undefined
    }
}
