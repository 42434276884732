import classNames from 'classnames'

const UsersIcon = ({ className, fill, ...props }: React.ComponentProps<'svg'>) => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill={fill} className={classNames('size-4', className)} {...props}>
        <path d="M7.5 8C9.15685 8 10.5 6.65685 10.5 5C10.5 3.34315 9.15685 2 7.5 2C5.84315 2 4.5 3.34315 4.5 5C4.5 6.65685 5.84315 8 7.5 8Z" fill="#0EA5E9" />
        <path d="M15 9C16.3807 9 17.5 7.88071 17.5 6.5C17.5 5.11929 16.3807 4 15 4C13.6193 4 12.5 5.11929 12.5 6.5C12.5 7.88071 13.6193 9 15 9Z" fill="#0EA5E9" />
        <path
            d="M2.11528 16.428C1.71798 16.1736 1.48785 15.721 1.54605 15.2529C1.91416 12.292 4.43944 10 7.4999 10C10.5604 10 13.0856 12.2914 13.4537 15.2522C13.512 15.7203 13.2818 16.1729 12.8845 16.4273C11.3302 17.4225 9.48243 18 7.4999 18C5.51737 18 3.66959 17.4231 2.11528 16.428Z"
            fill="#0EA5E9"
        />
        <path
            d="M15.0001 16C14.9647 16 14.9295 15.9998 14.8943 15.9993C14.9631 15.7025 14.9822 15.3885 14.9423 15.0671C14.7668 13.6562 14.2001 12.367 13.354 11.3116C13.8646 11.1105 14.4208 11 15.0028 11C16.9426 11 18.5956 12.2273 19.2279 13.9478C19.3638 14.3176 19.2045 14.7241 18.8671 14.9275C17.7379 15.6083 16.4147 16 15.0001 16Z"
            fill="#0EA5E9"
        />
    </svg>
)
export default UsersIcon