import MicrophoneStream from 'microphone-stream'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { toast } from 'react-toastify'

import { Language } from '../../../../../constants'
import { useAppContext } from '../../../../../hooks/useAppContext'
import { audio } from '../../../../../lib/audio'
import { AwsTranscriber, Credentials } from '../../../../../lib/AwsTranscriber'
import { AzureSTT } from '../../../../../lib/AzureSTT'
import { Whisper } from '../../../../../lib/Whisper'
import { eventBus, EventBusData } from '../../../../../services/event-bus/eventBus.service'
import { UpdateMediaDeviceState, UpdateRecordingState } from '../../../../../services/event-bus/events'
import { transcriptionsService } from '../../../../../services/http/transcriptions.service'
import { useDeveloperContext } from '../../../../Settings/Developer/hooks/useDeveloperContext'

export interface RecorderHookState {
    isRecording: boolean
    isPaused: boolean
    isLoading: boolean
    onRecord(): void
    onPause(): void
}

interface State {
    visitId: string | null
    status: Status
    transcriber: AwsTranscriber | Whisper | AzureSTT | null
    credentials: Credentials | null
}

const makeInitialState = (visitId: string | null): State => {
    return {
        visitId,
        status: 'paused',
        transcriber: null,
        credentials: null
    }
}

type Status = 'paused' | 'recording'

enum ACTION {
    INIT,
    RECORD,
    PAUSE
}

interface Action {
    type: ACTION
    payload?: AwsTranscriber | Whisper | AzureSTT | Credentials
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ACTION.INIT: {
            const credentials = action.payload as Credentials
            return {
                ...state,
                credentials
            }
        }

        case ACTION.RECORD: {
            const transcriber = action.payload as AwsTranscriber

            return {
                ...state,
                transcriber,
                status: 'recording'
            }
        }

        case ACTION.PAUSE: {
            state.transcriber?.stop()

            return {
                ...state,
                status: 'paused',
                transcriber: null
            }
        }

        default: {
            return state
        }
    }
}

export const useRecorder = (visitId: string, languageSource?: Language, languageTarget?: Language): RecorderHookState => {
    const { preferredMicrophoneDeviceId } = useAppContext().appSettings
    const { isAwsTranscriber, isWhisperTranscriber, isAzureTranscriber, downloadAudioFile, logAudioChunks } = useDeveloperContext().developer
    const [state, dispatch] = useReducer(reducer, makeInitialState(visitId))
    const { status, transcriber } = state
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const isRecording = status === 'recording'
    const isPaused = status === 'paused'

    const onRecord = useCallback(async () => {
        const isMicrophoneAvailable = await audio.isMicrophoneAvailable()
        if (!isMicrophoneAvailable) {
            toast.error('Unable to detect microphone')
            return
        }

        // start microphone
        let microphoneStream: MicrophoneStream | null = null,
            mediaRecorder: MediaRecorder | null = null

        try {
            [microphoneStream, mediaRecorder] = await audio.getStream()
        } catch (error) {
            toast.error('Unable to start audio streaming')
        }
        if (!microphoneStream) {
            toast.error('Unable to start audio streaming')
            return
        }

        let _transcriber
        if (isAwsTranscriber) {
            _transcriber = new AwsTranscriber(visitId!, preferredMicrophoneDeviceId ?? undefined)
            if (state.credentials && _transcriber && isAwsTranscriber) {
                _transcriber.setCredentials(state.credentials)
            }
        } else if (isWhisperTranscriber) {
            _transcriber = new Whisper(visitId!, languageSource ?? null, languageTarget ?? null, preferredMicrophoneDeviceId ?? undefined, downloadAudioFile, logAudioChunks)
        } else if (isAzureTranscriber) {
            _transcriber = new AzureSTT(visitId!, preferredMicrophoneDeviceId ?? undefined, languageSource, languageTarget)
        }

        if (!microphoneStream || !mediaRecorder) return
        _transcriber?.start(microphoneStream)

        dispatch({
            type: ACTION.RECORD,
            payload: _transcriber
        })
    }, [visitId, languageSource, languageTarget, preferredMicrophoneDeviceId, isAwsTranscriber, isWhisperTranscriber, isAzureTranscriber, downloadAudioFile, logAudioChunks, state.credentials])

    const onPause = useCallback(() => {
        dispatch({
            type: ACTION.PAUSE
        })
    }, [])

    // Initialize transcriber on load and set credentials
    useEffect(() => {
        if (isAwsTranscriber) {
            // eslint-disable-next-line no-extra-semi
            ;(async (visitId) => {
                try {
                    const credentials = await transcriptionsService.getCredentials(visitId)
                    dispatch({
                        type: ACTION.INIT,
                        payload: credentials
                    })
                } catch (error) {
                    console.error(error)
                }
            })(visitId)
        }
    }, [visitId, isAwsTranscriber])

    useEffect(() => {
        const subscription = eventBus.getObservable().subscribe((event: EventBusData) => {
            switch (event.action) {
                case UpdateMediaDeviceState.action: {
                    const { isAvailable } = event.payload
                    setIsLoading(isAvailable ? false : true)
                    break
                }

                case UpdateRecordingState.action: {
                    const { isRecording } = event.payload
                    if (isRecording === false) {
                        onPause()
                    }
                    break
                }
            }
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [onPause])

    // Stop transcriber on unmount
    useEffect(() => {
        return () => {
            transcriber?.stop()
        }
    }, [transcriber])

    return {
        isRecording,
        isPaused,
        isLoading,
        onRecord,
        onPause
    }
}
