import { ReactNode, useMemo } from 'react'
import * as Space from 'react-spaces'
import { IResizeHandleProps } from 'react-spaces/dist/core-react'
import styled from 'styled-components'

import Colors from '../../../colors'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { Plan } from '../../../services/models/Subscription.model'
import { useBillingContext } from '../../Billing/hooks/useBillingContext'
import { useVisitsContext } from '../../Visits/hooks/useVisitsContext'
import { SubscriptionStatusBanner } from '../SubscriptionStatusBanner'
import { VisitsUsageBanner } from '../VisitsUsageBanner'

interface LayoutStylesProps {
    leftNode: ReactNode
    rightNode: ReactNode
}

export const LayoutStyles = ({ leftNode, rightNode }: LayoutStylesProps) => {
    const { isMobile } = useMediaQuery()
    const { isListView, isPreview } = useVisitsContext().view
    const { isSubscribed, currentPlan } = useBillingContext().subscription

    const isFreePlan = useMemo(() => (currentPlan ? Plan.isFreePlan(currentPlan) : false), [currentPlan])

    const top = isSubscribed && !isFreePlan ? 64 : 64 + 48
    
    return (
        <>
            <SubscriptionStatusBanner />
            <VisitsUsageBanner />
            <Space.ViewPort top={top} className="flex h-full">
                {!isMobile && (
                    <Space.Left size={400} minimumSize={300} maximumSize={500} handleSize={3} handleRender={(props) => <ResizeHandle {...props} />} resizable>
                        {leftNode}
                    </Space.Left>
                )}

                {isMobile && isListView && <Space.Fill>{leftNode}</Space.Fill>}

                {isPreview && <Space.Fill>{rightNode}</Space.Fill>}
            </Space.ViewPort>
        </>
    )
}
const ResizeHandleStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: background 0.25s ease-in-out;

    &:hover {
        background: ${Colors.PRIMARY}CF;
    }
`

const ResizeHandle = (props: IResizeHandleProps) => {
    return <ResizeHandleStyles {...props} />
}
