import { createSlice } from '@reduxjs/toolkit'

interface Action {
    type?: string
    payload : MediaRecorder
}

interface initialState {
  mediaRecorder: MediaRecorder | null
}

const initialState : initialState = {
    mediaRecorder: null
}

const audioSlice = createSlice({
    initialState,
    name: 'audio',
    reducers: {
        addMediaRecorder(state, action: Action) {
            state.mediaRecorder = action.payload
        },
        clearMediaRecorder(state) {
            state.mediaRecorder = null
        }
    }
})

export const {addMediaRecorder, clearMediaRecorder} = audioSlice.actions
export default audioSlice.reducer
