import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ReactNode, useCallback } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useRoute } from '../../hooks/useRoute'
import { postAudioComplete } from '../../lib/Whisper'
import { OpenConfirmationModal } from '../../services/event-bus/events'
import { VisitType } from '../../services/models/Visit.model'
import { useDeveloperContext } from '../Settings/Developer/hooks/useDeveloperContext'
import { useVisitsContext } from '../Visits/hooks/useVisitsContext'

interface Props {
    className?: string
    visitId: string
    childNode(isLoading: boolean): ReactNode
    visitType ?: VisitType
}

const Styles = styled.div`
    display: inline-block;
`

export const DeleteVisit = ({ className, visitId, visitType='visit', childNode }: Props) => {
    const { navVisits } = useRoute()
    const { deleteVisit, isDeleteVisitLoading } = useVisitsContext().visits
    const { isAwsTranscriber } = useDeveloperContext().developer

    const onClick = useCallback(
        (event: any) => {
            event.stopPropagation()

            OpenConfirmationModal.emit({
                titleNode: 'Deleting visit',
                iconNode: (
                    <ExclamationTriangleIcon className="h-10 w-10 text-red mr-4" />
                ),
                bodyNode: `Are you sure you want to delete this ${visitType}?`,
                confirmLabel: 'Permanently delete',
                cancelIntent: 'danger',
                onConfirm: async () => {
                    try {
                        // Check if transcription has been completed by Whisper
                        if (!isAwsTranscriber) {
                            await postAudioComplete(visitId)
                        }
                    } catch (error) {
                        console.error('Error completing audio:', error)
                    } finally {
                        deleteVisit(visitId)
                            .then(() => {
                                toast.success(visitType+' deleted')
                                navVisits()
                            })
                            .catch((error) => console.error(error))
                    }
                },
            })
        },
        [visitId, isAwsTranscriber, deleteVisit, navVisits, visitType]
    )

    return (
        <Styles className={classNames(className)} onClick={onClick}>
            {childNode(isDeleteVisitLoading)}
        </Styles>
    )
}
