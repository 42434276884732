import {  ClipboardIcon, PrinterIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'

import PDFIcon from '../../UI/Icons/PDFIcon'
import { DictationStartPauseButton } from '../../VisitDictationShared/Components/PlayPauseButton'

export const ControllerBar = () => {
    return (
        <div className="flex py-2 items-center justify-start w-full ">
            <DictationStartPauseButton />
            {/* Icons */}
            <div className="flex items-center ml-9 gap-4 text-gray-500">
                <QuestionMarkCircleIcon width={21}/>
                <ClipboardIcon width={21}/>
                <PrinterIcon width={21} />
                <PDFIcon/>
            </div>
        </div>
    )
}
