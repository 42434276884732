import {
    BoltIcon
} from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ReactNode } from 'react'

import { Visit, VisitStateType } from '../../services/models/Visit.model'
import RecordingIcon from './Icons/RecordingIcon'
import { Tooltip } from './Tooltip'

interface Props {
    className?: string
    status: VisitStateType
}

export const VisitStatusIndicator = ({ className, status }: Props) => {
    return (
        <Tooltip
            content={
                <span>
                    <span>{Visit.getState(status)}:&nbsp;</span>
                    <span>{Visit.getStateDescription(status)}</span>
                </span>
            }
            placement="right"
        >
            <div className={classNames(className, 'cursor-pointer')}>
                {visitStateIconNodeRec[status]}
            </div>
        </Tooltip>
    )
}

const visitStateIconNodeRec: Record<VisitStateType, ReactNode> = {
    new: <RecordingIcon fill='#406CCF'/>,
    transcribing: <RecordingIcon fill='#406CCF'/>,
    processing: <BoltIcon className="h-4 w-4 text-gray animate-pulse" />,
    completed: null,
}
