import { createSlice } from '@reduxjs/toolkit'

interface Action {
    type: string
    payload: string
}

interface InitialState {
    dictation: string
}


const initialState: InitialState = {
    dictation: ''
}

const dictationSlice = createSlice({
    initialState,
    name: 'app',
    reducers: {
        changeDictationContent(state, action: Action) {
            state.dictation = action.payload
        },
        clearDictationContent(state) {
            state.dictation = ''
        }
    }
})

export const { changeDictationContent, clearDictationContent } = dictationSlice.actions
export default dictationSlice.reducer
