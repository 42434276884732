import { configureStore } from '@reduxjs/toolkit'

import appSlice from './reducers/app-slice'
import audioSlice from './reducers/audio-slice'
import dictationSlice from './reducers/dictation-slice'
import visitsSlice from './reducers/visits-slice'

const store = configureStore({
    reducer: {
        app: appSlice,
        dictation: dictationSlice,
        visits: visitsSlice,
        audio: audioSlice
    }
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
