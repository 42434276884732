import { MicrophoneIcon, StopIcon } from '@heroicons/react/20/solid'
import { useCallback, useEffect, useRef } from 'react'
import { LiveAudioVisualizer } from 'react-audio-visualize'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import Colors from '../../../colors'
import { RootState } from '../../../redux/store'
import { Loader } from '../../UI/Loader'
import { useLocalContext } from '../hooks/useLocalContext'

export const DictationStartPauseButton = () => {
    const { isRecording, isPaused, isLoading, onRecord, onPause } = useLocalContext().recorder
    const startRecordingOnLoad = !!useLocation().state?.startRecordingOnLoad
    const hasStartedRecordingRef = useRef<boolean>(false)
    const { mediaRecorder } = useSelector((state: RootState) => state.audio)

    // Start recording on load if requested
    useEffect(() => {
        if (!startRecordingOnLoad || hasStartedRecordingRef.current) {
            return
        }
        onRecord()
        hasStartedRecordingRef.current = true
    }, [startRecordingOnLoad, onRecord])

    const onClick = useCallback(
        (event: any) => {
            event.stopPropagation()

            if (isRecording) {
                onPause()
            } else if (isPaused) {
                onRecord()
            }
        },
        [isRecording, isPaused, onRecord, onPause]
    )

    return (
        <>
            {isRecording && mediaRecorder ? (
                <div className="flex items-center gap-4 h-10">
                    <button onClick={onClick} className="py-2 px-3 flex items-center gap-1 text-white bg-red rounded">
                        <StopIcon className="h-5 w-5" />
                        Stop
                    </button>
                    <LiveAudioVisualizer mediaRecorder={mediaRecorder} barWidth={5} gap={3} barColor={Colors.PRIMARY} height={'100%'} width={24} />
                    <span className='text-gray-400'>
                    Listening...
                    </span>
                </div>
            ) : (
                <button onClick={onClick} className="py-2 px-3 flex gap-1 items-center text-white bg-primary rounded">
                    {isLoading ? <Loader /> : <MicrophoneIcon className="h-5 w-5" />}
                    Start
                </button>
            )}
        </>
    )
}
