import classNames from 'classnames'

const MicrophoneIcon = ({ className, ...props }: React.ComponentProps<'svg'>) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" className={classNames('size-4', className)} {...props}>
            <path d="M7.5 4C7.5 2.34315 8.84315 1 10.5 1C12.1569 1 13.5 2.34315 13.5 4V10C13.5 11.6569 12.1569 13 10.5 13C8.84315 13 7.5 11.6569 7.5 10V4Z" fill="#14B8A6" />
            <path
                d="M6 9.64282C6 9.22861 5.66421 8.89282 5.25 8.89282C4.83579 8.89282 4.5 9.22861 4.5 9.64282V9.99997C4.5 13.0597 6.79027 15.5845 9.75 15.9535V17.5H8.25C7.83579 17.5 7.5 17.8358 7.5 18.25C7.5 18.6642 7.83579 19 8.25 19H12.75C13.1642 19 13.5 18.6642 13.5 18.25C13.5 17.8358 13.1642 17.5 12.75 17.5H11.25V15.9535C14.2097 15.5845 16.5 13.0597 16.5 9.99997V9.64282C16.5 9.22861 16.1642 8.89282 15.75 8.89282C15.3358 8.89282 15 9.22861 15 9.64282V9.99997C15 12.4852 12.9853 14.5 10.5 14.5C8.01472 14.5 6 12.4852 6 9.99997V9.64282Z"
                fill="#14B8A6"
            />
        </svg>
    )
}

export default MicrophoneIcon
