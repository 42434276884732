import React, { createContext, useContext, useMemo} from 'react'
import { useLocation } from 'react-router'

import { VisitType } from '../../../services/models/Visit.model'
import { RecorderHookState, useRecorder } from './_useRecorder'
import { TimerHookState, useTimer } from './_useTimer'

interface ContextState {
    recorder: RecorderHookState
    timer: TimerHookState
}

interface Props {
    visitId: string
    visitType: VisitType
    duration: number
    children: React.ReactNode
}

const LocalStateContext = createContext<ContextState>(null!)

// Provider
export function LocalContextProvider({ visitId, visitType, duration, children }: Props) {
    const locationState = useLocation().state
    const languageSource = useMemo(
        () => locationState?.languageSource,
        [locationState]
    )
    const languageTarget = useMemo(
        () => locationState?.languageTarget,
        [locationState]
    )

    const recorderHookState = useRecorder(
        visitId,
        visitType,
        languageSource,
        languageTarget
    )
    const timerHookState = useTimer(
        visitId,
        duration ?? 0,
        recorderHookState.isPaused
    )

    return (
        <LocalStateContext.Provider
            value={{
                recorder: recorderHookState,
                timer: timerHookState,
            }}
        >
            {children}
        </LocalStateContext.Provider>
    )
}

// Consumer
export function useLocalContext() {
    return useContext(LocalStateContext)
}
