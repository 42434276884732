import { TrashIcon } from '@heroicons/react/24/solid'

import { VisitType } from '../../../../../services/models/Visit.model'
import { DeleteVisit } from '../../../../UI/DeleteVisit'
import { IconButton } from '../../../../UI/IconButton'
import { Loader } from '../../../../UI/Loader'
import { ButtonLabel, ButtonWrapper } from '../styles'

interface Props {
    visitId: string
    visitType ?: VisitType
    concise?: boolean
}

export const DeleteButton = ({ visitId, visitType, concise = false }: Props) => {
    return (
        <ButtonWrapper>
            <DeleteVisit
                visitId={visitId}
                visitType = {visitType || 'visit'}
                childNode={(isLoading) => <IconButton className={`${visitType==='dictation'?'rounded-md':''}`} iconNode={isLoading ? <Loader className="h-5 w-5" /> : <TrashIcon className="h-5 w-5 text-gray-500" />} onClick={() => {}} />}
            />
            {!concise && <ButtonLabel>Delete</ButtonLabel>}
        </ButtonWrapper>
    )
}
