import MicrophoneStream from 'microphone-stream'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { Language } from '../../../constants'
import { useAppContext } from '../../../hooks/useAppContext'
import { audio } from '../../../lib/audio'
import { AwsTranscriber, Credentials } from '../../../lib/AwsTranscriber'
import { AzureSTT } from '../../../lib/AzureSTT'
import { DictationTranscriber } from '../../../lib/DictationTranscriber'
import { Whisper } from '../../../lib/Whisper'
import { addMediaRecorder, clearMediaRecorder } from '../../../redux/reducers/audio-slice'
import { eventBus, EventBusData } from '../../../services/event-bus/eventBus.service'
import { UpdateMediaDeviceState, UpdateRecordingState } from '../../../services/event-bus/events'
import { transcriptionsService } from '../../../services/http/transcriptions.service'
import { VisitType } from '../../../services/models/Visit.model'
import { useDeveloperContext } from '../../Settings/Developer/hooks/useDeveloperContext'

export interface RecorderHookState {
    isRecording: boolean
    isPaused: boolean
    isLoading: boolean
    onRecord(): void
    onPause(): void,
    transcriber: AwsTranscriber | Whisper | AzureSTT | DictationTranscriber | null
}

interface State {
    visitId: string | null
    status: Status
    transcriber: AwsTranscriber | Whisper | AzureSTT | DictationTranscriber | null
    credentials: Credentials | null
}

const makeInitialState = (visitId: string | null): State => {
    return {
        visitId,
        status: 'paused',
        transcriber: null,
        credentials: null
    }
}

type Status = 'paused' | 'recording'

enum ACTION {
    INIT,
    RECORD,
    PAUSE
}

interface Action {
    type: ACTION
    payload?: AwsTranscriber | Whisper | AzureSTT | Credentials | DictationTranscriber
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ACTION.INIT: {
            const credentials = action.payload as Credentials

            return {
                ...state,
                credentials
            }
        }

        case ACTION.RECORD: {
            const transcriber = action.payload as AwsTranscriber

            return {
                ...state,
                transcriber,
                status: 'recording'
            }
        }

        case ACTION.PAUSE: {
            state.transcriber?.stop()

            return {
                ...state,
                status: 'paused',
                transcriber: null
            }
        }

        default: {
            return state
        }
    }
}

export const useRecorder = (visitId: string, visitType: VisitType, languageSource?: Language, languageTarget?: Language): RecorderHookState => {
    const { preferredMicrophoneDeviceId } = useAppContext().appSettings
    const { isAwsTranscriber, isWhisperTranscriber, isAzureTranscriber, downloadAudioFile, logAudioChunks } = useDeveloperContext().developer
    const [state, dispatch] = useReducer(reducer, makeInitialState(visitId))
    const { status, transcriber } = state
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const isRecording = status === 'recording'
    const isPaused = status === 'paused'

    const reduxDispatch = useDispatch()

    const onRecord = useCallback(async () => {
        const isMicrophoneAvailable = await audio.isMicrophoneAvailable()
        if (!isMicrophoneAvailable) {
            toast.error('Unable to detect microphone')
            return
        }

        // start microphone
        let microphoneStream: MicrophoneStream | null = null,
            mediaRecorder: MediaRecorder | null = null

        try {
            [microphoneStream, mediaRecorder] = await audio.getStream()
        } catch (error) {
            toast.error('Unable to start audio streaming')
        }

        let _transcriber
        
        if (visitType === 'dictation') {
            _transcriber = new DictationTranscriber(preferredMicrophoneDeviceId ?? undefined)
        } else {
            if (isAwsTranscriber) {
                _transcriber = new AwsTranscriber(visitId!, preferredMicrophoneDeviceId ?? undefined)
                if (state.credentials) {
                    _transcriber.setCredentials(state.credentials)
                }
            } else if (isWhisperTranscriber) {
                _transcriber = new Whisper(visitId!, languageSource ?? null, languageTarget ?? null, preferredMicrophoneDeviceId ?? undefined, downloadAudioFile, logAudioChunks)
            } else if (isAzureTranscriber) {
                _transcriber = new AzureSTT(visitId!, preferredMicrophoneDeviceId ?? undefined, languageSource, languageTarget)
            }
        }

        if (!microphoneStream || !mediaRecorder) return

        await _transcriber?.start(microphoneStream)
        reduxDispatch(addMediaRecorder(mediaRecorder))

        // Set transcriber
        dispatch({
            type: ACTION.RECORD,
            payload: _transcriber
        })
    }, [
        visitId,
        languageSource,
        languageTarget,
        preferredMicrophoneDeviceId,
        isAwsTranscriber,
        isWhisperTranscriber,
        isAzureTranscriber,
        downloadAudioFile,
        logAudioChunks,
        state.credentials,
        reduxDispatch,
        visitType
    ])

    const onPause = useCallback(() => {
        dispatch({
            type: ACTION.PAUSE
        })
        reduxDispatch(clearMediaRecorder())
    }, [reduxDispatch])

    // Initialize transcriber on load and set credentials
    useEffect(() => {
        if (isAwsTranscriber) {
            // eslint-disable-next-line no-extra-semi
            ;(async (visitId) => {
                try {
                    const credentials = await transcriptionsService.getCredentials(visitId)
                    dispatch({
                        type: ACTION.INIT,
                        payload: credentials
                    })
                } catch (error) {
                    console.error(error)
                }
            })(visitId)
        }
    }, [visitId, isAwsTranscriber])

    useEffect(() => {
        const subscription = eventBus.getObservable().subscribe((event: EventBusData) => {
            switch (event.action) {
                case UpdateMediaDeviceState.action: {
                    const { isAvailable } = event.payload
                    setIsLoading(isAvailable ? false : true)
                    break
                }

                case UpdateRecordingState.action: {
                    const { isRecording } = event.payload
                    if (isRecording === false) {
                        onPause()
                    }
                    break
                }
            }
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [onPause])

    // Stop transcriber on unmount
    useEffect(() => {
        return () => {
            transcriber?.stop()
        }
    }, [transcriber])

    return {
        isRecording,
        isPaused,
        isLoading,
        onRecord,
        onPause,
        transcriber
    }
}
