import { DICTATION_COMMANDS } from '../../constants'
import { ShowSidebarButton } from '../UI/AppBar/ShowSidebarButton'
import { CreateVisit } from '../UI/CreateVisit'
import { VisitDictationNavbar } from '../UI/VisitDictationNavbar'
import { MicrophoneSettings } from '../VisitDictationShared/Components/MicrophoneSettings'
import { StartRecordButton } from '../VisitDictationShared/Components/StartRecordButton'

export const WelcomeState = () => {
    return (
        <>
            <div className="bg-white flex flex-col items-center justify-center h-full">
                <ShowSidebarButton />
                <VisitDictationNavbar />
                <div className='flex-1 h-full flex flex-col items-center justify-center text-center'>
                    <CreateVisit childNode={(isLoading) => <StartRecordButton isLoading={isLoading} />} type="dictation" />
                    <div className="mt-2 mb-4 sm:mt-4 sm:mb-6 text-lg font-semibold text-gray-800">Start new Dictation</div>
                    <MicrophoneSettings />
                    <div className="w-full max-w-md p-2 sm:p-0">
                        <div className="bg-gray-50 p-3 rounded-lg border border-gray-200 max-h-56 sm:max-h-60 overflow-y-auto">
                            <table className="table-auto">
                                <thead>
                                    <tr>
                                        <th className="px-1 py-2 border-b text-start font-semibold border-gray-200">Command</th>
                                        <th className="px-1 py-2 border-b font-semibold text-start border-gray-200">Result</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {DICTATION_COMMANDS.map((command, index) => (
                                        <tr className={`${index < DICTATION_COMMANDS.length - 1 && 'border-b border-gray-200'}`} key={index}>
                                            <td className="px-1 py-2 text-gray-700 text-start">{command.command}</td>
                                            <td className="px-1 py-2 text-gray-700 text-start">{command.result}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
