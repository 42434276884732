import 'react-quill/dist/quill.snow.css'
import './styles.scss'

import { forwardRef, MutableRefObject } from 'react'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'

import { RootState } from '../../../../redux/store'
import { useQuillEditor } from '../../../VisitDictationShared/hooks/useQuillEditor'
import { quillModules } from './quilljs'

interface Props {
    className?: string
    quillRef: MutableRefObject<ReactQuill | null>
}

// eslint-disable-next-line react/display-name
export const QuillEditor = forwardRef(({ quillRef }: Props) => {
    const { dictation } = useSelector((state: RootState) => state.dictation)

    const {handleContentChange} = useQuillEditor({ quillRef })

    return <ReactQuill ref={quillRef} value={dictation} onChange={handleContentChange} preserveWhitespace={true} theme="snow" modules={quillModules} />
})
