import { MicrophoneIcon } from '@heroicons/react/20/solid'

import { Loader } from '../../UI/Loader'

export const StartRecordButton = ({ isLoading }: { isLoading: boolean }) => {
    return (
        <div className="rounded-full h-20 w-20 bg-red shadow-lg flex items-center justify-center cursor-pointer">
            {isLoading ? <Loader className="h-6 w-6 text-white" /> : <MicrophoneIcon className="h-8 w-8 text-white" />}
        </div>
    )
}