import { useEffect, useRef, useState } from 'react'

import { timer } from '../../../lib/timer'
import { useVisitsContext } from '../../Visits/hooks/useVisitsContext'

export interface TimerHookState {
    duration: number
}

export const useTimer = (visitId: string, initialDuration: number, isPaused: boolean = true): TimerHookState => {
    const [duration, setDuration] = useState<number>(initialDuration)
    const durationRef = useRef<number>(initialDuration)
    const { updateDuration } = useVisitsContext().visits

    useEffect(() => {
        const _duration = timer.getSeconds()
        if (durationRef.current === _duration) {
            return
        }
        durationRef.current = _duration

        const subscription = timer.getObservable().subscribe((seconds) => {
            setDuration(seconds)
        })

        return () => {
            // Unsubscribe from timer observable
            subscription.unsubscribe()

            // Update visit duration in memory
            const _duration = timer.getSeconds()

            if (_duration !== initialDuration) {
                updateDuration(visitId, _duration)
            }

            // Reset the timer back to 0
            timer.reset()
        }
    }, [visitId, updateDuration, initialDuration])

    useEffect(() => {
    // If recording is paused, pause the timer and update visit duration in memory
        const _duration = timer.getSeconds()
        if (durationRef.current === _duration) {
            return
        }
        durationRef.current = _duration

        // If recording is paused, pause the timer and update visit duration in memory
        if (isPaused) {
            timer.pause(_duration || initialDuration)
            if (_duration !== initialDuration) {
                updateDuration(visitId, _duration)
            }
            return
        }

        // If recording is not paused, get the timer continue ticking
        timer.tick(timer.getSeconds() || initialDuration)
    }, [visitId, initialDuration, isPaused, updateDuration])

    return {
        duration
    }
}
